import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FindTaskRoutingModule } from './find-task-routing.module';
import {
  NgbAlertModule,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ServicesModule } from '../../shared/services/services.module';
import { HttpClientModule } from '@angular/common/http';
import { FindTaskComponent } from './find-task.component';
import { SharedComponentsModule } from '../../components-gallery/shared/shared.module';
import {
  RECAPTCHA_LANGUAGE,
  RecaptchaFormsModule,
  RecaptchaModule,
} from 'ng-recaptcha';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'cs',
    },
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaFormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FindTaskRoutingModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgbAlertModule,
    NgbModule,
    ServicesModule,
    MatSnackBarModule,
    NgbTooltipModule,
    HttpClientModule,
    SharedComponentsModule,
    MatTooltipModule,
    MatSelectModule,
  ],
  declarations: [FindTaskComponent],
  entryComponents: [],
})
export class FindTaskModule {}
