<mat-toolbar>
    <h2 mat-dialog-title>Smazat soubory</h2>
    <span class="flex"></span>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>clear</mat-icon>
    </button>
  <ngb-alert [type]="'danger'" *ngIf="isUnknownError" [dismissible]="false">
    {{ 'errors.unknownError' | translate }}
  </ngb-alert>
</mat-toolbar>
<mat-dialog-content>
  <p>Opravdu chcete smazat vybrané soubory ({{filesToRemoveIds.length}})?</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="accent" mat-dialog-close>
    Zrušit
  </button>
  <button mat-raised-button color="primary" (click)="removeFile()">
    Potvrdit
  </button>
</mat-dialog-actions>
