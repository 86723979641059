import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadTaskFileService } from './upload-task-file.service';
import { RemoveTaskFileService } from './remove-task-file.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    UploadTaskFileService,
    RemoveTaskFileService,
  ],
})
export class TasksFileModule {}
