import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskFileInterface } from './definitions/task-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';
import { taskDetailQuery } from '../../../../admin/task-detail/task-detail.data';
import { cloneDeep } from '@apollo/client/utilities';

interface FileUploadResponse {
  taskFile: {
    upload: {
      output: TaskFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class UploadTaskFileService {
  constructor(private apollo: Apollo) {}

  uploadFile(
    taskId: string,
    taskFileGroupId: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Observable<FetchResult<FileUploadResponse>> {
    return this.apollo.mutate<FileUploadResponse>({
      mutation: mutation,
      variables: {
        taskId: taskId,
        taskFileGroupId: taskFileGroupId,
        name: name,
        content: btoa(content),
        extension: extension,
      },
      optimisticResponse: {
        taskFile: {
          upload: {
            output: {
              id: customPlaceHolderId
                ? customPlaceHolderId
                : '-' + Math.random(),
              task: null,
              taskFileGroup: null,
              size: 0,
              enablePreview: false,
              createdAt: new Date(),
              updatedAt: new Date(),
              removedAt: null,
              type: null,
              src: null,
              thumbnails: [],
              taskId: taskId,
              taskFileGroupId: taskFileGroupId,
              name: name,
              extension: extension,
            },
            errors: {
              errors: [],
              status: 0,
              message: '',
            },
          },
        },
      },
      update: (proxy, mutationResult) => {
        try {
          let data = <{ task: any }>(
            proxy.readQuery({
              query: taskDetailQuery,
              variables: { id: taskId },
            })
          );
          if (
            mutationResult.data.taskFile.upload.output !== undefined &&
            mutationResult.data.taskFile.upload.output !== null
          ) {
            data = cloneDeep(data);
            data.task.byId.output.taskFiles.push(
              mutationResult.data.taskFile.upload.output
            );
          }
          proxy.writeQuery({
            query: taskDetailQuery,
            variables: { id: taskId },
            data,
          });
        } catch (e) {}
      },
    });
  }
}
