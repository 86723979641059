import {
  AfterViewInit,
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @ViewChild('slider') slider: ElementRef;
  @ViewChild('webkitfix') webkitfix: ElementRef;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  private _value = 0;
  private _slideTimeoutID: NodeJS.Timer;
  private _slideIntervalID: NodeJS.Timer;

  @Input()
  set value(val: number) {
    val = Number(val);
    if (val > this.max) {
      val = this.max;
    } else if (val < this.min) {
      val = this.min;
    }
    this._value = val;
    if (this.webkitfixNeeded) {
      this.updateWebkitfix();
    }
    this.valueChange.emit(this.value);
  }
  get value(): number {
    return this._value;
  }

  @Output() valueChange = new EventEmitter();

  get webkitfixNeeded(): boolean {
    return 'WebkitAppearance' in document.documentElement.style;
  }

  get webkitfixWidth(): number {
    if (this.slider && this.slider.nativeElement) {
      return (
        (this.slider.nativeElement.offsetWidth / (this.max - this.min)) *
        (this.value - this.min)
      );
    }
    return 0;
  }

  constructor() {}

  ngAfterViewInit(): void {
    if (this.webkitfixNeeded) {
      this.initWebkitfix();
    }
  }

  initWebkitfix(): void {
    if (this.webkitfixWidth) {
      this.updateWebkitfix();
    } else {
      setTimeout(this.initWebkitfix.bind(this), 500);
    }
  }

  updateWebkitfix(): void {
    if (this.webkitfix && this.webkitfix.nativeElement) {
      this.webkitfix.nativeElement.style.width = this.webkitfixWidth + 'px';
    }
  }

  slideStart(mul: number): void {
    this.value += this.step * mul;
    this._slideTimeoutID = setTimeout(() => {
      this._slideIntervalID = setInterval(() => {
        this.value += this.step * mul;
      }, 100);
    }, 500);
  }

  slideEnd(): void {
    clearTimeout(this._slideTimeoutID);
    clearInterval(this._slideIntervalID);
    // if (this._slideTimeoutID && !this._slideIntervalID)
    //   if (this._slideStop !== this.fileWidth)
    //     this.fileWidth += this._slideStep;
    this._slideTimeoutID = this._slideIntervalID = null;
  }

  public toNumber(value: string): number {
    return parseFloat(value);
  }
}
