import { constants } from './constants';

export function getUrlParams(urlSearch) {
  const urlParams = {};
  let match;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s) {
    return decodeURIComponent(s.replace(pl, ' '));
  };
  while ((match = search.exec(urlSearch))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }
  return urlParams;
}

export function getUrlInfo(path) {
  const link = document.createElement('a');
  link.setAttribute('href', path);
  return {
    host: link.hostname,
    port: link.port,
    searchString: link.search.substr(1),
    search: getUrlParams(link.search.substr(1)),
    path: link.pathname,
    protocol: link.protocol,
  };
}

export function insertUrlParam(origUrl, key, value) {
  let searchString = getUrlInfo(origUrl).searchString;
  let baseUrl = origUrl;
  if (searchString) {
    searchString = searchString + '&' + key + '=' + value;
    baseUrl = origUrl.split('?')[0];
  } else {
    searchString = key + '=' + value;
  }
  return baseUrl + '?' + searchString;
}

export function applyUrl(url, forceDownload = false, isEnabled = true) {
  if (!forceDownload && !isEnabled) {
    return;
  }
  const updatedUrl = forceDownload
    ? insertUrlParam(url, constants.FORCE_DOWNLOAD_PARAM_KEY, 1)
    : insertUrlParam(url, constants.FORCE_DOWNLOAD_PARAM_KEY, 0);
  if (forceDownload) {
    window.location.href = updatedUrl;
  } else {
    window.open(updatedUrl);
  }
}
