import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerListService } from './partner-list.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    PartnerListService,
  ],
})
export class PartnersModule {}
