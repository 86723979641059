import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskUpdateService } from './update.service';
import { CompleteTaskService } from './complete.service';
import { TaskRemoveService } from './remove.service';
import { TaskByTokenIdService } from './by-token-id.service';
import { OnlineTaskInspectionDoneService } from './online-task-inspection-done.service';
import { CreateOnlineTaskInspectionService } from './create-online-task-inspection.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TaskUpdateService,
    TaskByTokenIdService,
    CompleteTaskService,
    TaskRemoveService,
    OnlineTaskInspectionDoneService,
    CreateOnlineTaskInspectionService,
  ],
  exports:[]
})
export class TasksModule {}
