import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlineTaskInspectionDetailRoutingModule } from './online-task-inspection-detail-routing.module';
import {
  NgbAlertModule,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from '../../components-gallery/shared/shared.module';
import { ServicesModule } from '../../shared/services/services.module';
import { HttpClientModule } from '@angular/common/http';
import { OnlineTaskInspectionDetailComponent } from './online-task-inspection-detail.component';
import { ConfirmRemoveFilesOnlineInspectionComponent } from './form/confirm-remove-file/confirm-remove-files-online-inspection.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OverlayModule } from '@angular/cdk/overlay';
import { PhotosComponent } from './form/photos/photos.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OnlineTaskInspectionDetailRoutingModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgbAlertModule,
    MatDatepickerModule,
    SharedComponentsModule,
    NgbModule,
    ServicesModule,
    MatSnackBarModule,
    NgbTooltipModule,
    HttpClientModule,
    MatTooltipModule,
    MatToolbarModule,
    OverlayModule,
  ],
  declarations: [
    PhotosComponent,
    OnlineTaskInspectionDetailComponent,
    ConfirmRemoveFilesOnlineInspectionComponent
  ],
  entryComponents: [ConfirmRemoveFilesOnlineInspectionComponent],
})
export class OnlineTaskInspectionDetailModule {}
