import { Injectable } from '@angular/core';
import { PartnerListService } from '../graphql/partners/partner-list.service';
import { Observable } from 'rxjs';
import { PartnerInterface } from '../graphql/partners/definitions/partner-interface';
import { DocumentNode } from 'graphql';
import { map } from 'rxjs/operators';

@Injectable()
export class PartnersService {
  constructor(
    private partnerListService: PartnerListService,
  ) {}

  getOnlineTaskInspectionPartners(query: DocumentNode): Observable<Array<any>> {
    return this.partnerListService.getList(null, query).pipe(
      map((partnerData) => {
        return partnerData.data.partner.listOnlineTaskInspection.output.map(
          (pd) => this._mapPartner(pd)
        );
      })
    );
  }

  private _mapPartner(partnerData): PartnerInterface {
    return { ...partnerData };
  }
}
