import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { constants } from '../../shared/utils/constants';
import { LoggerService } from '../../shared/services/logger.service';
import { OnlineTaskInspectionDetail } from './online-task-inspection-detail.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { taskDataQuery } from './online-task-inspection-detail.data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-photos',
  templateUrl: './online-task-inspection-detail.component.html',
  styleUrls: ['./online-task-inspection-detail.component.scss'],
})
export class OnlineTaskInspectionDetailComponent implements OnInit, OnDestroy {
  @Input() task: OnlineTaskInspectionDetail;
  private routeSubscription: Subscription;
  private taskSubscription: Subscription;
  public isServerError = false;
  public constants = constants;
  public messages = [];
  public cantSendAlert: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private tasksService: TasksService
  ) {
  }

  showSentAttachmentButton(): boolean {
    if (!this.task) {
      return false;
    }
    return (
      this.task.tokenType ===
      this.constants.TOKEN_TYPE_CLIENT_ONLINE_TASK_INSPECTION
    ) && 
    this.canUploadFiles();
  }

  public canUploadFiles(): boolean {
    if (this.task.tokenType === this.constants.TOKEN_TYPE_CLIENT_SMS_UPLOAD) {
      return true;
    }
    if (this.task.tokenType === this.constants.TOKEN_TYPE_CLIENT_ONLINE_TASK_INSPECTION && this.task.state.name === constants.TASK_STATE_INFORMATION_GATHERING) {
      return true;
    }
    return false;
  }

  getTask(tokenId: string): void {
    const taskObs = this.tasksService.getTaskByTokenIdAndWatch(
      tokenId.toString(),
      taskDataQuery
    );
    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }
    this.taskSubscription = taskObs.subscribe(
      (taskDetail: OnlineTaskInspectionDetail) => {
        this.task = taskDetail;
      },
      (error) => {
        this.isServerError = true;
        this.router.navigate(['/online-task-inspection/find-task']);
      }
    );
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((data) => {
      this.getTask(data.token);
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }
  }

  onlineTaskInspectionDone(): void {
    this.routeSubscription = this.route.params.subscribe((data) => {
      if(this.task.taskFiles.length === 0) {
        this.cantSendAlert = true;
        return;
      } else {
        this.cantSendAlert = false;
      }
      const promise = this.tasksService.onlineTaskInspectionDone(
        this.task.id,
        data.token
      );
      promise
        .then(() => {
          this.router.navigate(['online-task-inspection/thanks']);
        })
        .catch((err) => {
          this.logger.error('Done online inspection failed', err);
          alert('Nastala chyba při ukončování online prohlídky');
        });
    });
  }
}
