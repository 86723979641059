import { Component } from '@angular/core';

@Component({
  selector: 'app-online-task-inspection',
  templateUrl: './online-task-inspection.component.html',
  styleUrls: ['./online-task-inspection.component.scss'],
})
export class OnlineTaskInspectionComponent {
  constructor() {}
}
