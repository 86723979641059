import { ImageViewer } from 'iv-viewer-ts';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { LightboxFileInterface } from './lightbox-interface';
import { applyUrl } from '../../../shared/utils/url-service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements AfterViewInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('lbImageContainer') imageContainer: ElementRef;
  @ViewChild('lbLightboxContainer') lightboxContainer: ElementRef;
  public files: Array<LightboxFileInterface> = [];
  public active: number | null = null;
  public activeUnsupportedFormat: boolean = false;
  public isEmptyFile: boolean = false;
  private imageViewer: ImageViewer;
  private zoom: number = 100;
  public static formats = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'jfif'];
  public loadingFailed = false;

  constructor() {}

  @HostListener('document:keydown', ['$event']) onKeyDown(
    event: KeyboardEvent
  ): void {
    if (event.key === 'ArrowRight') {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.onNext();
    } else if (event.key === 'ArrowLeft') {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.onPrevious();
    } else if (event.key === 'Escape') {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.onClose();
    }
  }

  public ngAfterViewInit(): void {
    if (this.imageContainer && !this.imageViewer) {
      const container = this.imageContainer.nativeElement;
      this.imageViewer = new ImageViewer(container, {
        listeners: {
          onImageError: () => {
            this.loadingFailed = true;
          },
          onImageLoaded: () => {
            this.loadingFailed = false;
          }
        }
      });
      this.showFile(this.active !== null ? this.active : 0);
    }
  }

  public ngOnDestroy(): void {
    if (this.imageViewer) {
      this.imageViewer.destroy();
    }
  }

  public setFiles(files: Array<LightboxFileInterface>, active: number): void {
    this.files = files;
    this.active = active;
    this.showFile(active);
  }

  public onPrevious(): void {
    if (this.active === null) {
      return;
    }
    let active = this.active - 1;
    if (active < 0) {
      active = this.files.length - 1;
    }
    this.showFile(active);
  }

  public onNext(): void {
    if (this.active === null) {
      return;
    }
    this.showFile((this.active + 1) % this.files.length);
  }

  public onZoomIn(): void {
    this.zoom += 25;
    if (this.zoom > 500) {
      this.zoom = 500;
    }
    this.imageViewer.zoom(this.zoom);
  }

  public onZoomOut(): void {
    this.zoom -= 25;
    if (this.zoom < 100) {
      this.zoom = 100;
    }
    this.imageViewer.zoom(this.zoom);
  }

  public onReset(): void {
    this.zoom = 100;
    this.imageViewer.refresh();
  }

  public onFileDownload(): void {
    applyUrl(this.files[this.active].src, true);
  }

  public onClose(): void {
    this.close.emit();
  }

  private showFile(index: number): void {
    if (this.imageViewer && this.files[index]) {
      this.active = index;
      const file = this.files[index];
      this.activeUnsupportedFormat = !LightboxComponent.formats.includes(
        file.extension.toLowerCase()
      );
      this.isEmptyFile = file.size === 0;
      this.imageViewer.load(file.src);
      this.zoom = 100;
    }
  }
}
