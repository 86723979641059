import { Component, Inject, Optional } from '@angular/core';
import { ConfirmRemoveFilesComponent } from '../../../../admin/shared/confirm-remove-file/confirm-remove-files.component';
import { removeTaskFileMutation } from '../../../../admin/task-detail/task-tabs/task-tabs.data';
import { LoggerService } from '../../../../shared/services/logger.service';
import { TasksService } from '../../../../shared/services/tasks/tasks.service';
import { taskDataQuery } from '../../online-task-inspection-detail.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-online-inspection-confirm-remove-file',
  templateUrl:
    '../../../../admin/shared/confirm-remove-file/confirm-remove-files.component.html',
  styleUrls: [
    '../../../../admin/shared/confirm-remove-file/confirm-remove-files.component.scss',
  ],
})
export class ConfirmRemoveFilesOnlineInspectionComponent extends ConfirmRemoveFilesComponent {
  tokenId: string = null;

  constructor(
    protected logger: LoggerService,
    protected tasksService: TasksService,
    protected client: Apollo,
    protected dialogRef: MatDialogRef<ConfirmRemoveFilesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(logger, tasksService, client, dialogRef, data);
    this.tokenId = data.tokenId;
  }

  async removeFile() {
    try {
      const promiseArray = [];
      this.filesToRemoveIds.forEach((id) =>
        promiseArray.push(
          this.tasksService.removeTaskFile(id, removeTaskFileMutation)
        )
      );
      const removeTaskFile = await Promise.all(promiseArray);
      if (!removeTaskFile.find((taskFile) => taskFile.errors)) {
        this.client
          .query({
            query: taskDataQuery,
            variables: {
              tokenId: this.tokenId,
            },
            fetchPolicy: 'network-only',
          })
          .toPromise()
          .then();
        this.closeDialog();
      } else {
        this.isUnknownError = true;
      }
    } catch (error) {
      this.logger.error('Task file removing failed', error);
      this.isUnknownError = true;
    }
  }
}
