<div class="online-task-inspection-page">
  <div class="header-section">
    <a href="https://klobal.cz" target="_blank">
      <img src="/assets/img/logo-white.png" alt="Pojišťovna Klobal logo" class="logo">
    </a>
  </div>
  <section class="main-container">
    <router-outlet></router-outlet>
  </section>

  <div class="contact-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 contact-title">
          <h3>Kontakt:</h3>
        </div>

        <div class="col-md-4">
          <div class="card mat-elevation-z5 mt-3">
            <div class="card-title row">
              <div class="md-12">
                Kontaktujte nás
              </div>
            </div>
            <div class="card-content row">
              <div class="md-12">
                <span>KLOBAL s.r.o.</span><br>
                <span>+420 468 006 300</span><br>
                <span>info@klobal.cz</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card mat-elevation-z5 mt-3">
            <div class="card-title row">
              <div class="col-12">
                O společnosti
              </div>
            </div>

            <div class="card-content row">
              <div class="col-md-6">
                <span>KLOBAL s.r.o.</span><br>
                <span>Hlaváčova 395</span><br>
                <span>530 02 Pardubice</span>
              </div>

              <div class="col-md-6">
                <span>IČ: 25656562</span><br>
                <span>DIČ: CZ25656562</span><br>
                <span>ČNB: 232288SLPU</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
