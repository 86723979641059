import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphqlModule } from './graphql/graphql.module';
import { TasksModule } from './tasks/tasks.module';
import { PartnersModule } from './partners/partners.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule } from './logger.service';
import { IdGeneratorModule } from './id-generator.service';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [
    // AuthModule,
    GraphqlModule,
    TasksModule,
    PartnersModule,
    LoggerModule,
    TranslateModule,
    IdGeneratorModule
  ],
})
export class ServicesModule {}
