import { TasksService } from '../../shared/services/tasks/tasks.service';
import { AttachmentDialogComponent } from '../../components-gallery/shared/attachment-dialog/attachment-dialog.component';
import {
  AfterContentChecked,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { constants } from '../../shared/utils/constants';
import { LoggerService } from '../../shared/services/logger.service';
import { FormBuilder } from '@angular/forms';
import {
  OnlineTaskInspectionDetail,
  TaskFile,
} from './online-task-inspection-detail.interface';
import {
  taskDataQuery,
  uploadTaskFileMutation,
} from './online-task-inspection-detail.data';
import { ConfirmRemoveFilesOnlineInspectionComponent } from './form/confirm-remove-file/confirm-remove-files-online-inspection.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';

@Component({
  template: '',
})
export class AttachmentComponent implements AfterContentChecked, OnInit {
  loading = false;
  task: OnlineTaskInspectionDetail;
  fileDragSourceId: string | null = null;
  subscription: Subscription;
  selectedFileList: Array<string> = [];
  isUnknownError = false;
  filesLoadedPercentage: Array<{ id: string; loaded: number }> = [];
  attachDialogRef: MatDialogRef<AttachmentDialogComponent> = null;
  searchString = '';
  @ViewChild('input') inputComponent;
  focusPending = false;

  constructor(
    public tasksService: TasksService,
    public fb: FormBuilder,
    public logger: LoggerService,
    public client: Apollo,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
  }

  get taskFilesWithoutGroup(): Array<TaskFile> {
    if (this.task === null || this.task === undefined) {
      return [];
    }
    return this.task.taskFiles.filter((file) => {
      return !file.taskFileGroupId;
    });
  }

  ngOnInit() {}

  @HostListener('document:click', ['$event'])
  onClick(ev: Event) {
    const target = <HTMLElement>ev.target || ev.srcElement;
    if ((target as any).id !== 'card') {
      this.selectedFileList = [];
    }
  }

  ngAfterContentChecked(): void {
    if (this.focusPending && this.inputComponent) {
      this.inputComponent.nativeElement.focus();
      this.focusPending = false;
    }
  }

  async saveNewTaskFile(taskId: string, file: File, fileGroupId: string) {
    if (file.size > constants.MAX_UPLOAD_FILE_SIZES.ATTACHMENT) {
      this.snackBar.open(
        'Nepodařilo se soubor uložit. Maximální velikost nahrávaného souboru je 50MB',
        undefined,
        {
          duration: 3000,
        }
      );
      return;
    }
    try {
      this.loading = true;
      const placeholderId = '-' + Math.random();
      this.filesLoadedPercentage.push({ id: placeholderId, loaded: 0 });
      let timer = setTimeout(() => {
        const file = this.filesLoadedPercentage.find(
            (fileSource) => fileSource.id === placeholderId
        );
        if (file === null || file === undefined) {
          return;
        }

        file.loaded = 30;
        timer = setTimeout(() => {
          const file = this.filesLoadedPercentage.find(
              (fileSource) => fileSource.id === placeholderId
          );
          if (file === null || file === undefined) {
            return;
          }

          file.loaded = 60;
          timer = setTimeout(() => {
            const file = this.filesLoadedPercentage.find(
                (fileSource) => fileSource.id === placeholderId
            );
            if (file === null || file === undefined) {
              return;
            }

            file.loaded = 90;
          }, 500);
        }, 500);
      }, 500);
      const uploadFileResponse = await this.tasksService.uploadFileType(
        taskId,
        fileGroupId,
        file,
        uploadTaskFileMutation,
        placeholderId
      );
      console.log(uploadFileResponse)
      this.loading = false;
      const uploadFile = uploadFileResponse.data.taskFile.upload;

      if (uploadFile === null) {
        return;
      }

      clearTimeout(timer);
      this.filesLoadedPercentage.push({ id: uploadFile.output.id, loaded: 99 });
      setTimeout(() => {
        this.filesLoadedPercentage.push({
          id: uploadFile.output.id,
          loaded: 100,
        });
        this.filesLoadedPercentage.splice(
          this.filesLoadedPercentage.findIndex(
            (fileSource) => fileSource.id === placeholderId
          )
        );
        this.filesLoadedPercentage.splice(
          this.filesLoadedPercentage.findIndex(
            (fileSource) => fileSource.id === uploadFile.output.id
          )
        );
      }, 1000);
      if (!!uploadFile.output) {
        this.client
          .query({
            query: taskDataQuery,
            variables: {
              tokenId: this.task.tokenId,
            },
            fetchPolicy: 'network-only',
          })
          .toPromise()
          .then();
      } else {
        this.isUnknownError = true;
      }
    } catch (error) {
      if (error.message === 'Cant upload file into images') {
        this.snackBar.open('Soubor nelze vložit mezi obrázky', 'OK', {
          duration: 3000,
        });
      }
      this.logger.error(
        'Setting task file group document missing failed',
        error
      );
      this.isUnknownError = true;
      this.loading = false;
    }
  }

  async deleteFile(...taskFileIds: string[]) {
    this.dialog.open(ConfirmRemoveFilesOnlineInspectionComponent, {
      disableClose: true,
      data: {
        filesToRemoveIds: [...taskFileIds],
        task: this.task,
        isInternalFile: false,
        tokenId: this.task.tokenId,
      },
    });
  }

  deleteAllSelectedFiles() {
    this.dialog.open(ConfirmRemoveFilesOnlineInspectionComponent, {
      disableClose: true,
      data: {
        filesToRemoveIds: this.selectedFileList,
        task: this.task,
        tokenId: this.task.tokenId,
      },
    });
  }

  handleFileSelect(event) {
    this.selectFile(event);
  }

  selectFile(event) {
    if (event.isMultiSelecting) {
      this._selectNextFile(event.fileId);
    } else {
      this.selectedFileList = [event.fileId];
    }
  }

  _selectNextFile(fileId) {
    const index = this._fileSelectedIndex(fileId);
    if (index >= 0) {
      this.selectedFileList.splice(index, 1);
    } else if (index === -1) {
      this.selectedFileList.push(fileId);
    }
  }

  _fileSelectedIndex(fileId) {
    return this.selectedFileList.findIndex((selected) => selected === fileId);
  }
}
