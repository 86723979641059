<div class="before"
     (mousedown)="slideStart(-1)"
     (mouseup)="slideEnd()"
     (mouseleave)="slideEnd()">
</div>

<div #webkitfix
     id="webkitfix"
     *ngIf="webkitfixNeeded">
     <!-- style="width={{webkitfixWidth}}"> -->
</div>

<input #slider
       type="range"
       min="{{min}}"
       max="{{max}}"
       step="{{step}}"
       value="{{value}}"
       (input)="value = toNumber(slider.value)" />

<div class="after"
     (mousedown)="slideStart(1)"
     (mouseup)="slideEnd()"
     (mouseleave)="slideEnd()">
</div>
