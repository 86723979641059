import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import onlineTaskInspectionDoneMutation from './mutations/online-task-inspection-done';

interface OnlineTaskInspectionDoneResponse {
  task: {
    onlineTaskInspectionDone: {
      output: boolean;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class OnlineTaskInspectionDoneService {
  constructor(private apollo: Apollo) {}

  onlineTaskInspectionDone(taskId: string, tokenId: string) {
    return this.apollo.mutate<OnlineTaskInspectionDoneResponse>({
      mutation: onlineTaskInspectionDoneMutation,
      variables: {
        taskId,
        tokenId,
      },
    });
  }
}
