<mat-card [class.selected]="selected"
          dnd-draggable
          [dragEnabled]="!isDragDisabled"
          [dragData]="serverFileData.id"
          (onDragStart)="onDragStart.emit($event)"
          (onDragEnd)="onDragEnd.emit()"
          [ngStyle]="{ width: width + 'px'}">

  <a *ngIf="!isLightboxSupported; else fileCard" target="_blank" href="{{serverFileData.src}}">
    <ng-container *ngTemplateOutlet="fileCard"></ng-container>
  </a>

  <ng-template #fileCard>
    <div id="card" style="position: absolute;width: 100%;height: 100%;left: 0;top: 0;z-index: 1;">
    </div> <!-- Fix for dragging, any better ideas? -->
    <div class="mdl-card__media" ngClass="{{serverFileData.extension}}">
      <img src="{{previewPath}}" *ngIf="hasPreview === true && loadingFailed === false" class="img" loading="lazy" fetchpriority="low" (error)="onLoadingFailed()">
      <div class="suffix text" *ngIf="(isEmpty !== true && hasPreview !== true) || (isEmpty !== true && loadingFailed === true)">{{serverFileData.extension}}</div>
      <div class="suffix text text-danger text-center" *ngIf="isEmpty">
        <mat-icon class="text-danger">warning</mat-icon><br>
        <small>Prázdný soubor</small>
      </div>
      <div class="loader" *ngIf="!_isLoaded">
        <mat-progress-bar
          class="wrapper"
          [color]="'primary'"
          [mode]="'determinate'"
          [value]="uploadedPercent">
        </mat-progress-bar>
      </div>
    </div>
  </ng-template>

  <mat-card-content>
    <div class="row align-items-center">
      <div class="col">
        <h3>{{serverFileData.name}}</h3>
      </div>
    </div>
    <span class="number">{{badge}}</span>
  </mat-card-content>
</mat-card>
