import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';

export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const INFO = 'INFO';

if (environment.sentry.allow) {
  var integrations = [
    Sentry.browserTracingIntegration()
  ];

  if (environment.sentry.allowReplay == true) {
    integrations.push(Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }));
  }
  
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: integrations,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.environment
  });
}

@Injectable()
export class LoggerService {
  constructor() {}

  log(message: string, object?: any, level?: string) {
    if (environment.enableConsoleLog) {
      console.log(`${level ? level : INFO}: ${message}\n`, object);
    }
    if (environment.sentry.allow) {
      if (object instanceof Error) {
        Sentry.captureException(object);
      } else {
        Sentry.captureMessage(message);
      }
    }
  }

  error(message: string, object?: any) {
    this.log(message, object, ERROR);
  }

  warning(message: string, object?: any) {
    this.log(message, object, WARNING);
  }
}

@NgModule({
  imports: [CommonModule],
  providers: [LoggerService],
})
export class LoggerModule {}
