import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OnlineTaskInspectionModule } from './online-task-inspection/online-task-inspection.module';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ConfirmRemoveFilesComponent } from './admin/shared/confirm-remove-file/confirm-remove-files.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedComponentsModule } from './components-gallery/shared/shared.module';
import { ServicesModule } from './shared/services/services.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TasksService } from './shared/services/tasks/tasks.service';
import { LoggerService } from './shared/services/logger.service';
import { provideApolloClientOptions } from 'src/graphql/client';
import { HttpLink } from 'apollo-angular/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';

// const routes: Routes = [{ path: '', component: FindTaskComponent }];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?v=' + Date.now()
  );
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmRemoveFilesComponent,
  ],
  imports: [
    AppRoutingModule,
    ServicesModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    OnlineTaskInspectionModule,
    SharedComponentsModule,
    MatIconModule,
    MatToolbarModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    TasksService,
    LoggerService,
    { provide: LOCALE_ID, useValue: 'cs-CS' },
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApolloClientOptions,
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
