import { Component, Input, ViewChild } from '@angular/core';
import { TasksService } from '../../../../shared/services/tasks/tasks.service';
import { AttachmentComponent } from '../../attachment-component';
import { constants } from '../../../../shared/utils/constants';
import { LoggerService } from '../../../../shared/services/logger.service';
import { FormBuilder } from '@angular/forms';
import { OnlineTaskInspectionDetail } from '../../online-task-inspection-detail.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-online-inspection-upload-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent extends AttachmentComponent {
  @Input() task: OnlineTaskInspectionDetail;
  @ViewChild('avatar') avatarRef;
  constants = constants;

  constructor(
    public tasksService: TasksService,
    public fb: FormBuilder,
    public logger: LoggerService,
    public client: Apollo,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super(tasksService, fb, logger, client, dialog, snackBar);
  }

  get selectedFile() {
    if (this.task !== null && this.task !== undefined) {
      const needle = this.task.taskFiles.find(
        (taskFile) => taskFile.id === this.selectedFileList[0]
      );
      if (needle) {
        return needle;
      }
    }
    return {
      name: 'Žádný soubor nevybrán',
      id: '1',
      extension: '',
      thumbnails: [{ src: '' }],
    };
  }
}
