export function readInputFile(file): Promise<any> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    if (reader.readAsBinaryString) {
      reader.onload = async (e) => {
        const target: any = e.target;
        resolve(target.result);
      };
      reader.readAsBinaryString(file);
    } else {
      // Catering for IE 10/11
      reader.onload = async (e) => {
        const target: any = e.target;
        let binary = '';
        const bytes = new Uint8Array(target.result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        resolve(binary);
      };
      reader.readAsArrayBuffer(file);
    }
  });
}
