import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskServiceInterface } from './definitions/task-service-interface';
import { Observable } from 'rxjs';
import { UpdateTaskInterface } from './definitions/task-update-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface UpdateResponse {
  task: {
    update: {
      output: TaskServiceInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TaskUpdateService {
  constructor(private apollo: Apollo) {}

  update(
    taskId: string,
    updatePatch: UpdateTaskInterface,
    mutation: DocumentNode
  ): Observable<FetchResult<UpdateResponse>> {
    return this.apollo.mutate<UpdateResponse>({
      mutation: mutation,
      variables: {
        id: taskId,
        ...updatePatch,
      },
    });
  }
}
