import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlineTaskInspectionComponent } from './online-task-inspection.component';
import { OnlineTaskInspectionGuard } from '../shared/guard/online-task-inspection.guard';

const routes: Routes = [
  {
    path: '',
    component: OnlineTaskInspectionComponent,
    children: [
      {
        path: '',
        redirectTo: 'find-task',
        pathMatch: 'full',
      },
      {
        path: 'find-task',
        loadChildren: () =>
          import('./find-task/find-task.module').then((m) => m.FindTaskModule),
        pathMatch: 'full',
      },
      {
        path: 'thanks',
        loadChildren: () =>
          import('./thanks/thanks.module').then((m) => m.ThanksModule),
      },
      {
        path: ':token',
        loadChildren: () =>
          import(
            './online-task-inspection-detail/online-task-inspection-detail.module'
          ).then((m) => m.OnlineTaskInspectionDetailModule),
        canActivate: [OnlineTaskInspectionGuard],
      },
      { path: '**', redirectTo: 'not-found' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnlineTaskInspectionRoutingModule {}
