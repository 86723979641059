import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentDialogComponent } from './attachment-dialog/attachment-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DndModule } from 'ng2-dnd';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { LightboxComponent } from './lightbox/lightbox.component';
import { NgxMaskModule } from 'ngx-mask'
import { DropFileLoaderComponent } from './drop-file-loader/drop-file-loader.component';
import { VerticalFilesBlockComponent } from './vertical-files-block/vertical-files-block.component';
import { SliderComponent } from './slider/slider.component';
import { FileComponent } from './file/file.component';

@NgModule({
  imports: [
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DndModule.forRoot(),
    MatToolbarModule,
    MatAutocompleteModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    MatSliderModule,
    MatFormFieldModule,
    MatGridListModule,
    RouterModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    AttachmentDialogComponent,
    DropFileLoaderComponent,
    FileComponent,
    DndModule,
    MatIconModule,
    MatCheckboxModule,
    LightboxComponent,
    VerticalFilesBlockComponent,
    SliderComponent
  ],
  declarations: [
    AttachmentDialogComponent,
    FileComponent,
    DropFileLoaderComponent,
    LightboxComponent,
    VerticalFilesBlockComponent,
    SliderComponent
  ],
  entryComponents: [AttachmentDialogComponent],
})
export class SharedComponentsModule {}
