import { gql } from 'apollo-angular';
import { errorsTypeDef } from '../../errors/errors-type-def';

const onlineTaskInspectionDoneMutation = gql`
  mutation onlineTaskInspectionDone($taskId: String!, $tokenId: String!) {
    task {
      onlineTaskInspectionDone(input: {
        taskId: $taskId
        tokenId: $tokenId
      }) {
        output {
          success
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;

export default onlineTaskInspectionDoneMutation;
