import gql from 'graphql-tag';
import { taskFileTypeDef } from '../../../shared/services/graphql/task-file/definitions/task-file-type-def';
import { taskInternalFileTypeDef } from '../../../shared/services/graphql/task-internal-file/definitions/task-internal-file-type-def';


export const removeTaskFileMutation = gql`
  mutation removeTaskFile (
  $taskFileId: String!
  ) {
    taskFile{
      remove(input: {
        id: $taskFileId
      }) {
        output {
          ${taskFileTypeDef}
        }
      }
    }
  }
`;

export const removeInternalTaskFileMutation = gql`
  mutation removeInternalTaskFile (
  $taskFileId: String!
  ) {
    taskInternalFile{
      remove(input: {
        id: $taskFileId
      }) {
        output {
          ${taskInternalFileTypeDef}
        }
      }
    }
  }`;
