import { ApolloLink } from '@apollo/client/core';

const repairHeaderWare = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  if (!context.headers) {
    context.headers = {}; // Create the headers object if needed.
  }
  context.headers['contentType'] = 'application/json; charset=utf-8';
  operation.setContext(context);
  return forward(operation);
});

export default repairHeaderWare;
