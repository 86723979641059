import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { PartnerInterface } from './definitions/partner-interface';
import { ListServiceBase } from '../../../classes/list-service-base';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface PartnerListResponse {
  partner: {
    list: {
      output: Array<PartnerInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class PartnerListService extends ListServiceBase {
  constructor(private apollo: Apollo) {
    super();
  }

  getQuery(variables, querySource: DocumentNode): QueryRef<any> {
    return this.apollo.watchQuery<PartnerListResponse>({
      query: querySource,
      fetchPolicy: 'network-only',
    });
  }
}
