<div class="wrapper"
     (dragover)="openFix($event);dragStart($event)"
     (dragstart)="dragStart($event)"
     (dragend)="dragging = false;"
     (dragleave)="dragging = false;">
  <div class="overlay"
       [class.hide]="!dragging || !active"
       (drop)="processFile($event)"
       (dragend)="dragging = false;"
       (dragleave)="dragging = false;"
  >
    <div>
      <strong>Přetáhněte sem</strong> soubory z plochy
    </div>
  </div>
  <div class="upload_overlay" *ngIf="filesToUpload.length > 0">
    <div class="files">
      <app-file *ngFor="let f of filesToUpload"
                [uploadedPercent]="f.loaded"
      ></app-file>
    </div>
  </div>
  <ng-content></ng-content>
</div>
