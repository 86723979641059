import gql from 'graphql-tag';
import { errorsTypeDef } from '../../shared/services/graphql/errors/errors-type-def';

export const createOnlineTaskInspectionMutation = gql`
  mutation createOnlineTaskInspection($partnerId: String!, $claimNumber: String!, $carLicensePlate: String!) {
    task {
      createOnlineInspection(input: {
        partnerId: $partnerId,
        claimNumber: $claimNumber
        carLicensePlate: $carLicensePlate
      }) {
        output {
          tokenId
          onlineTaskInspectionUrl
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const listInsuranceCompanyQuery = gql`
  query listInsuranceCompanyQuery {
    partner {
      listOnlineTaskInspection {
        output {
          id
          name
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;
