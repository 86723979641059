import { environment } from '../environments/environment';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { provideNetworkInterfaceWithSubscriptions } from './network-interface';
import { HttpLink } from 'apollo-angular/http';

const dataIdFromObject = (o): string | null | undefined => {
  if (typeof o.id !== 'undefined') {
    return o.id;
  }
  return null;
};

export function provideApolloClientOptions(httpLink: HttpLink): {
  cache: InMemoryCache;
  link: ApolloLink;
} {
  let networkInterface;
  if (environment.useMocks) {
    throw new Error('Mocks are no longer supproted. Set useMocks to false');
  } else {
    networkInterface = provideNetworkInterfaceWithSubscriptions(httpLink);
  }

  const cache = new InMemoryCache({ dataIdFromObject })
  cache.policies.addTypePolicies({
    Query: {
      fields: {
        task: {
          merge: true
        },
        employee: {
          merge: true
        }
      }
    },
    Mutation: {
      fields: {
        task: {
          merge: true,
        },
        taskFileGroup: {
          merge: true,
        },
      },
    },
  });

  return {
    cache,
    link: networkInterface,
  };
}
