import gql from 'graphql-tag';
import { errorsTypeDef } from '../../shared/services/graphql/errors/errors-type-def';

export const taskDataQuery = gql`
  query taskByTokenId($tokenId: String!) {
    task {
      byTokenId(input: {
        tokenId: $tokenId
      }) {
        output {
          id
          tokenId
          tokenType
          claimNumber
          carLicensePlate
          state {
            name
          }
          partner {
            name
          }
          taskFiles {
            id
            name
            src
            extension
            thumbnails {
              type
              width
              height
              src
            }
            taskFileGroupId
            taskFileGroup {
              id
            }
          },
          auth {
                token
                tokenType
                expiresAt
                expiresIn
                user {
                    id
                    type
                }
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const uploadTaskFileMutation = gql`
  mutation taskFileUpload (
  $taskId: String!
  $taskFileGroupId: String
  $name: String!
  $content: String!
  $extension: String!
  ) {
    taskFile{
      upload(input: {
        taskId: $taskId
        taskFileGroupId: $taskFileGroupId
        file: {
          name: $name
          content: $content
          extension: $extension
        }
      }) {
        output {
          id
          name
          type
          src
          thumbnails {
            type
            src
          }
          extension
          taskId
          taskFileGroupId
          taskFileGroup {
            id
            taskId
            taskFileGroupTypeId
            taskFileGroupType {
              id
              name
              type
              source
            }
            source
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;
