import { taskInternalFileThumbnailTypeDef } from './task-internal-file-thumbnail-type-def';

export const taskInternalFileTypeDef = `
    id
    taskId
    task {
      id
    }
    name
    size
    extension
    createdAt
    updatedAt
    removedAt
    type
    src
    thumbnails {
      ${taskInternalFileThumbnailTypeDef}
    }
`;
