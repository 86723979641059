<app-loading-overlay *ngIf="loading" [isFullscreenOverlay]="true"></app-loading-overlay>

<div class="find-task-page">
  <div class="find-task-form-section">

    <div class="container">
      <div class="row">
        <div class="col-lg-7 order-lg-1 col-md-12">
          <form [formGroup]="findTaskForm" (ngSubmit)="findTask()">
            <div class="row form">
              <div class="col-md-12">
                <div class="card mat-elevation-z15">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-12 mb-4">
                          <ng-container *ngIf="!onlineInspectionFailed && !onlineTaskInspectionInvalidInput && !onlineTaskInspectionInvalidRecaptcha">
                            <h4 class="mt-0">Znáte údaje?</h4>
                            <h3>Najděte si svou pojistnou událost.</h3>
                          </ng-container>

                          <ng-container *ngIf="onlineInspectionFailed">
                            <h4 class="mt-0 text-danger">Nebylo nic nenalezeno.</h4>
                            <h3>Zadali jste správné údaje? Pokud ano, kontaktujte nás.</h3>
                          </ng-container>

                          <ng-container *ngIf="onlineTaskInspectionInvalidInput &&
                                                                      (findTaskForm.controls.carLicensePlate.status !== 'VALID' ||
                                                                      findTaskForm.controls.claimNumber.status !== 'VALID' ||
                                                                      findTaskForm.controls.partnerId.status !== 'VALID')">
                            <h4 class="mt-0 text-danger">Nemůžeme vyhledat.</h4>
                            <h3>Vypadá to, že zvýrazněné údaje chybí nebo nejsou validní.</h3>
                          </ng-container>

                          <ng-container *ngIf="onlineTaskInspectionInvalidInput &&
                                                                      findTaskForm.controls.carLicensePlate.status === 'VALID' &&
                                                                      findTaskForm.controls.claimNumber.status === 'VALID' &&
                                                                      findTaskForm.controls.partnerId.status === 'VALID'">
                            <h4 class="mt-0 text-success">Vše opraveno?</h4>
                            <h3>Pokud ano, můžete vyhledat znova.</h3>
                          </ng-container>

                          <ng-container *ngIf="onlineTaskInspectionInvalidRecaptcha && findTaskForm.controls.recaptchaKey.status !== 'VALID'">
                            <h4 class="mt-0 text-warning">Není možné vyhledat.</h4>
                            <h3>Před vyhledáním se nám ověřte, že nejste robot.</h3>
                          </ng-container>

                          <ng-container *ngIf="onlineTaskInspectionInvalidRecaptcha && findTaskForm.controls.recaptchaKey.status === 'VALID'">
                            <h4 class="mt-0 text-success">Děkujeme za ověření.</h4>
                            <h3>Nyní již můžete zkusit vyhledat.</h3>
                          </ng-container>
                        </div>

                        <div class="col-12">
                          <div class="form-input d-flex align-items-center"
                               [ngClass]="{'border-danger': onlineTaskInspectionInvalidInput && findTaskForm.controls.partnerId.status !== 'VALID'}">
                            <mat-icon class="input-icon d-flex justify-content-start">business</mat-icon>
                            <div class="form-input-wrapper d-flex justify-content-start flex-grow-1 align-items-center">
                              <mat-select formControlName="partnerId" placeholder="Vyberte pojišťovnu"
                                          class="selectWithBorder">
                                <mat-option *ngFor="let insuranceCompany of insuranceCompanies; let i = index"
                                            [value]="insuranceCompany.id">
                                  {{insuranceCompany.name}}
                                </mat-option>
                              </mat-select>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-input d-flex align-items-center"
                               [ngClass]="{'border-danger': onlineTaskInspectionInvalidInput && findTaskForm.controls.claimNumber.status !== 'VALID'}"
                               matTooltip="Číslo pojistné události musí obsahovat alespoň 5 znaků.">
                            <mat-icon class="input-icon d-flex justify-content-start">assignment</mat-icon>
                            <div class="form-input-wrapper d-flex justify-content-start flex-grow-1 align-items-center">
                              <input matInput formControlName="claimNumber"
                                     (keyup)="findTaskForm.patchValue({claimNumber: $event.target.value.toUpperCase()})"
                                     (change)="findTaskForm.patchValue({claimNumber: $event.target.value.toUpperCase()})"
                                     placeholder="Číslo pojistné události" #claimNumberInput
                                     maxlength="35">
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-input d-flex align-items-center"
                               [ngClass]="{'border-danger': onlineTaskInspectionInvalidInput && findTaskForm.controls.carLicensePlate.status !== 'VALID'}"
                               matTooltip="Registrační značka vozidla musí obsahovat alespoň 5 znaků.">
                            <mat-icon class="input-icon d-flex justify-content-start">directions_car</mat-icon>
                            <div class="form-input-wrapper d-flex justify-content-start flex-grow-1 align-items-center">
                              <input matInput formControlName="carLicensePlate"
                                     (keyup)="findTaskForm.patchValue({carLicensePlate: $event.target.value.toUpperCase()})"
                                     (change)="findTaskForm.patchValue({carLicensePlate: $event.target.value.toUpperCase()})"
                                     placeholder="Registrační značka vozidla" #carLicensePlateInput
                                     maxlength="20">
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="mt-4 mb-4 d-flex justify-content-center">
                            <re-captcha #reCaptchaElement
                                        formControlName="recaptchaKey"
                                        required
                                        size="normal"
                                        siteKey="{{recaptchaSiteKey}}"
                            ></re-captcha>
                          </div>
                        </div>

                        <div class="col-12">
                          <input class="btn mt-2" type="submit" value="Vyhledat">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-5 order-lg-0 col-md-12">
          <div class="description">
            <h1>Pojistná událost online</h1>
            <dl class="mt-4 mb-5">
              <dd>{{"portal.findTask.firstParagraph" | translate}}</dd>
              <dd>{{"portal.findTask.secondParagraph" | translate}}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
