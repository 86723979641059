import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { getOperationAST } from 'graphql';
import wsClient from './wsclient';
import { concat, ApolloLink } from '@apollo/client/core';
import repairHeaderWare from './transformers/repair-header-ware';
import authWare from './transformers/auth-ware';

export function provideNetworkInterfaceWithSubscriptions(
  httpLink: HttpLink
): ApolloLink {
  const networkInterface = httpLink.create({ uri: environment.apollo.apiUrl });
  return ApolloLink.split(
    (operation) => {
      const operationAST = getOperationAST(
        operation.query,
        operation.operationName
      );
      return !!operationAST && operationAST.operation === 'subscription';
    },
    wsClient,
    concat(concat(repairHeaderWare, authWare), networkInterface)
  );
}
