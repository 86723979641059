<div class="lb-lightbox lb-overlay" (keyup.escape)="onClose()" (keyup.arrowLeft)="onPrevious()" (keyup.arrowRight)="onNext()">
  <div class="lb-toolbar">
    <div class="lb-toolbar-text">
      <p class="me-4 mb-0">
        {{active + 1}} / {{files.length}}
      </p>
      <p class="mb-0">
        <ng-container *ngIf="files[active].name">
          <strong >{{files[active].name}}</strong><br>
        </ng-container>
        <span *ngIf="files[active].caption">{{files[active].caption}}</span>
      </p>
      <p>
    </div>

    <a class="lb-zoom-in" (click)="onZoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </a>
    <a class="lb-zoom-out" (click)="onZoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </a>
    <a class="lb-reset" (click)="onReset()">
      <mat-icon>crop_free</mat-icon>
    </a>
    <a class="lb-file-download" (click)="onFileDownload()">
      <mat-icon>file_download</mat-icon>
    </a>
    <a class="lb-close" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <a class="lb-button-prev" (click)="onPrevious()">
    <mat-icon>west</mat-icon>
  </a>
  <a class="lb-button-next" (click)="onNext()">
    <mat-icon>east</mat-icon>
  </a>
  <div #lbImageContainer id="lb-image-container" class="lb-image-container"></div>
  <div *ngIf="loadingFailed == true" class="lb-image-format-unsupported">
    <p class="align-middle">
      Při načítání souboru došlo k chybě.<br>
    </p>
  </div>
  <div *ngIf="activeUnsupportedFormat || isEmptyFile" class="lb-image-format-unsupported">
    <p class="align-middle">
      Soubor není možné zobrazit.<br>
      <a href="{{files[active].src}}" target="_blank">{{files[active].name}} ({{files[active].extension}})</a>
    </p>
  </div>
</div>

