import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskInternalFileInterface } from './definitions/task-internal-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface RemoveInternalFileTaskResponse {
  taskInternalFile: {
    remove: {
      output: TaskInternalFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class RemoveInternalTaskFileService {
  constructor(private apollo: Apollo) {}

  removeInternalTaskFile(
    taskFileId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<RemoveInternalFileTaskResponse>> {
    return this.apollo.mutate<RemoveInternalFileTaskResponse>({
      mutation: mutation,
      variables: {
        taskFileId: taskFileId,
      },
    });
  }
}
