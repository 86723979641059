import { taskFileThumbnailTypeDef } from './task-file-thumbnail-type-def';
import { taskFileGroupTypeTypeDef } from '../../task-file-group/definitions/task-file-group-type-type-def';

export const taskFileTypeDef = `
    id
    taskId
    task {
      id
    }
    taskFileGroupId
    taskFileGroup {
      id
      taskId
      taskFileGroupTypeId
      taskFileGroupType {
        ${taskFileGroupTypeTypeDef}
      }
      source
    }
    name
    size
    extension
    createdAt
    updatedAt
    removedAt
    type
    src
    thumbnails {
      ${taskFileThumbnailTypeDef}
    }
`;
