import { authRestoreToken } from '../../app/shared/utils';
import { recaptchaRestoreKey } from '../../app/shared/utils';
import { ApolloLink } from '@apollo/client/core';

const authWare = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  if (!context.headers) {
    context.headers = {}; // Create the headers object if needed.
  }

  const authData = authRestoreToken();
  if (authData) {
    const auth = authRestoreToken();
    const authToken = auth ? auth.token : null;
    if (authToken) {
      context.headers['authorization'] = `Bearer ${authToken}`;
    }
  }

  const recaptchaKey = recaptchaRestoreKey();
  if (recaptchaKey) {
    context.headers['recaptcha'] = recaptchaKey;
  }

  // add the authorization to the headers
  operation.setContext(context);

  return forward(operation);
});

export default authWare;
