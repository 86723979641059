import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

let idCount = 0;

@Injectable()
export class IdGeneratorService {
  constructor() {}

  getId(prefix: string): string {
    return `${prefix}-${idCount++}`;
  }
}

@NgModule({
  imports: [CommonModule],
  providers: [IdGeneratorService],
})
export class IdGeneratorModule {}
