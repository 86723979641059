import { Component, Inject, Optional } from '@angular/core';
import { TaskDetail } from '../../task-detail/task-detail.interface';
import { LoggerService } from '../../../shared/services/logger.service';
import { TasksService } from '../../../shared/services/tasks/tasks.service';
import {
  removeInternalTaskFileMutation,
  removeTaskFileMutation,
} from '../../task-detail/task-tabs/task-tabs.data';
import { taskDetailQuery } from '../../task-detail/task-detail.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-confirm-remove-file',
  templateUrl: './confirm-remove-files.component.html',
  styleUrls: ['./confirm-remove-files.component.scss'],
})
export class ConfirmRemoveFilesComponent {
  filesToRemoveIds: Array<string> = [];
  task: TaskDetail;
  isUnknownError = false;
  filesRemoved = 0;
  isInternalFile = false;

  constructor(
    protected logger: LoggerService,
    protected tasksService: TasksService,
    protected client: Apollo,
    protected dialogRef: MatDialogRef<ConfirmRemoveFilesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filesToRemoveIds = data.filesToRemoveIds;
    this.task = data.task;
    this.isInternalFile = data.isInternalFile;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async removeFile() {
    if (this.isInternalFile) {
      try {
        const promiseArray = [];
        this.filesToRemoveIds.forEach((id) =>
          promiseArray.push(
            this.tasksService.removeInternalTaskFile(
              id,
              removeInternalTaskFileMutation
            )
          )
        );
        const removeTaskFile = await Promise.all(promiseArray);
        if (!removeTaskFile.find((taskFile) => taskFile.errors)) {
          this.client
            .query({
              query: taskDetailQuery,
              variables: {
                id: this.task.id,
              },
              fetchPolicy: 'network-only',
            })
            .toPromise()
            .then();
          this.closeDialog();
        } else {
          this.isUnknownError = true;
        }
      } catch (error) {
        this.logger.error('Task file removing failed', error);
        this.isUnknownError = true;
      }
    } else {
      try {
        const promiseArray = [];
        this.filesToRemoveIds.forEach((id) =>
          promiseArray.push(
            this.tasksService.removeTaskFile(id, removeTaskFileMutation)
          )
        );
        const removeTaskFile = await Promise.all(promiseArray);
        if (!removeTaskFile.find((taskFile) => taskFile.errors)) {
          this.client
            .query({
              query: taskDetailQuery,
              variables: {
                id: this.task.id,
              },
              fetchPolicy: 'network-only',
            })
            .toPromise()
            .then();
          this.closeDialog();
        } else {
          this.isUnknownError = true;
        }
      } catch (error) {
        this.logger.error('Task file removing failed', error);
        this.isUnknownError = true;
      }
    }
  }
}
