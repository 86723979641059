import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentFileInterface } from '../attachment-block/attachment-file-interface';

@Component({
  selector: 'app-drop-file-loader',
  templateUrl: './drop-file-loader.component.html',
  styleUrls: ['./drop-file-loader.component.scss'],
})
export class DropFileLoaderComponent {
  @Input() filesToUpload: Array<AttachmentFileInterface> = [];
  @Input() active = true;
  @Output() file: EventEmitter<{ file: File }> = new EventEmitter();
  _dragging = false;
  private _dragTimeout;

  get dragging(): boolean {
    return this._dragging;
  }

  set dragging(val: boolean) {
    clearTimeout(this._dragTimeout);
    if (val) {
      this._dragging = true;
    } else {
      this._dragTimeout = setTimeout(() => {
        this._dragging = false;
      }, 100);
    }
  }

  constructor() {}

  openFix(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }

  processFile(e: DragEvent): void {
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      this.file.emit({ file: e.dataTransfer.files.item(i) });
    }
    e.stopPropagation();
    e.preventDefault();
    this.dragging = false;
  }

  dragStart(event: DragEvent): void {
    const areOnlyFiles = event.dataTransfer.types.every(
      (type) => type === 'Files' || type === 'application/x-moz-file'
    );
    if (this.active && areOnlyFiles) {
      this.dragging = true;
    }
  }
}
