import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { constants } from '../../shared/utils/constants';
import { LoggerService } from '../../shared/services/logger.service';
import { InsuranceCompany, OnlineTaskInspection } from './find-task.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  createOnlineTaskInspectionMutation,
  listInsuranceCompanyQuery,
} from './find-task.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnersService } from '../../shared/services/partners/partners.service';
import { recaptchaStoreKey, recaptchaClearKey } from '../../shared/utils';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-find-task',
  templateUrl: './find-task.component.html',
  styleUrls: ['./find-task.component.scss'],
})
export class FindTaskComponent implements OnInit, OnDestroy {
  @Input()
  task!: OnlineTaskInspection;
  @ViewChild('reCaptchaElement')
  reCaptchaElement!: RecaptchaComponent;
  insuranceCompanies: Array<InsuranceCompany> = [];
  recaptchaSiteKey = '6LfFZeIUAAAAABoRlc9uO2zqwpkh5fqGAHZm-qn6';
  isServerError = false;
  loading = false;
  onlineInspectionFailed = false;
  onlineTaskInspectionInvalidInput = false;
  onlineTaskInspectionInvalidRecaptcha = false;
  constants = constants;
  messages = [];
  subscriptions: any[] = [];

  public findTaskForm: FormGroup = this.fb.group({
    partnerId: [
      { value: null, disabled: false },
      Validators.compose([Validators.required]),
    ],
    claimNumber: [
      { value: '', disabled: false },
      Validators.compose([
        Validators.required,
        Validators.maxLength(35),
        Validators.minLength(5),
      ]),
    ],
    carLicensePlate: [
      { value: '', disabled: false },
      Validators.compose([
        Validators.required,
        // Validators.pattern('^[A-Z0-9]+[\- ]?[A-Z0-9]+$'),
        Validators.maxLength(20),
        Validators.minLength(5),
      ]),
    ],
    recaptchaKey: [
      { value: null, disabled: false },
      Validators.compose([Validators.required]),
    ],
  });

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private tasksService: TasksService,
    private partnersService: PartnersService
  ) {}

  ngOnInit(): void {
    this.resolveUrlParameters();

    this.subscriptions.push(
      this.partnersService
        .getOnlineTaskInspectionPartners(listInsuranceCompanyQuery)
        .subscribe(
          (data) => {
            this.insuranceCompanies = data;

          },
          (error) => {
            this.logger.error('Loading enumeration failed', error);
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  async findTask() {
    this.onlineInspectionFailed = false;
    this.onlineTaskInspectionInvalidInput = false;
    this.onlineTaskInspectionInvalidRecaptcha = false;

    if (this.findTaskForm.status !== 'VALID') {
      if (
        this.findTaskForm.controls.claimNumber.status === 'VALID' &&
        this.findTaskForm.controls.carLicensePlate.status === 'VALID' &&
        this.findTaskForm.controls.partnerId.status === 'VALID'
      ) {
        this.onlineTaskInspectionInvalidRecaptcha = true;
      } else {
        this.onlineTaskInspectionInvalidInput = true;
      }

      this.reCaptchaElement.reset();
      this.loading = false;
      return;
    }

    this.loading = true;

    recaptchaStoreKey(this.findTaskForm.value.recaptchaKey);
    const claimNumber = this.findTaskForm.value.claimNumber;
    const carLicensePlate = this.findTaskForm.value.carLicensePlate.replace(
      /\s/g,
      ''
    );
    const partnerId = this.findTaskForm.value.partnerId;

    const task = await this.tasksService.createOnlineInspection(
      partnerId,
      claimNumber,
      carLicensePlate,
      createOnlineTaskInspectionMutation
    );


    recaptchaClearKey();
    if (!task.tokenId || !task.onlineTaskInspectionUrl) {
      this.reCaptchaElement.reset();
      this.onlineInspectionFailed = true;
      this.loading = false;
      return;
    }

    this.router.navigate([`online-task-inspection/${task.tokenId}`]);
  }

  private resolveUrlParameters(): void {
    this.route.queryParams.subscribe(params => {
      const claimNumber = params['claimNumber'] ?? null;
      const partnerId = params['partnerId'] ?? null;
      const carLicensePlate = params['carLicensePlate'] ?? null;

      if (claimNumber !== null) {
        this.findTaskForm.controls['claimNumber'].setValue(claimNumber);
      }

      if (partnerId !== null) {
        this.findTaskForm.controls['partnerId'].setValue(partnerId);
      }

      if (carLicensePlate !== null) {
        this.findTaskForm.controls['carLicensePlate'].setValue(carLicensePlate);
      }
    });
  }
}
