import { Injectable } from '@angular/core';
import { TaskServiceInterface } from '../graphql/tasks/definitions/task-service-interface';
import { Observable } from 'rxjs';
import { UploadTaskFileService } from '../graphql/task-file/upload-task-file.service';
import { TaskByTokenIdService } from '../graphql/tasks/by-token-id.service';
import { Subscription } from 'rxjs';
import { UpdateTaskInterface } from '../graphql/tasks/definitions/task-update-interface';
import { TaskUpdateService } from '../graphql/tasks/update.service';
import { TaskFileInterface } from '../graphql/task-file/definitions/task-file-interface';
import { constants } from '../../utils/constants';
import { readInputFile } from '../../utils/file-reader';
import { TaskFileGroupInterface } from '../graphql/task-file-group/definitions/task-file-group-interface';
import { CompleteTaskService } from '../graphql/tasks/complete.service';
import { TaskListVariablesInterface } from '../graphql/tasks/definitions/task-list-variables-interface';
import { RemoveTaskFileService } from '../graphql/task-file/remove-task-file.service';
import { RevisionMessageInterface } from '../graphql/task-revision/definitions/revision-message-interface';
import { RemoveInternalTaskFileService } from '../graphql/task-internal-file/remove-internal-task-file.service';
import { TaskRemoveService } from '../graphql/tasks/remove.service';
import { NavigationStart, Router } from '@angular/router';
import { DocumentNode } from 'graphql';
import { OnlineTaskInspectionDoneService } from '../graphql/tasks/online-task-inspection-done.service';
import { CreateOnlineTaskInspectionService } from '../graphql/tasks/create-online-task-inspection.service';
import { CreateOnlineInspectionInterface } from '../graphql/tasks/definitions/create-online-inspection-interface';
import {  map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class TasksService {
  updateSubscription: Subscription | null = null;
  tasksUnknownError = false;
  taskHasBeenAssumed = false;
  successfullyAssumed = false;
  taskListVariables: TaskListVariablesInterface = {
    searchText: null,
    states: [
      constants.TASK_STATE_NEW,
      constants.TASK_STATE_INFORMATION_GATHERING,
      constants.TASK_STATE_DOCUMENTS_MISSING,
      constants.TASK_STATE_CALCULATION_REQUIRED,
      constants.TASK_STATE_CALCULATED,
      constants.TASK_STATE_REVISION,
      constants.TASK_STATE_REVISED,
      constants.TASK_STATE_COMPLETED,
      constants.TASK_STATE_INVOICED,
    ],
    partners: null,
    assignedTo: null,
    onlyMy: true,
    offset: 0,
    limit: 25,
    orderDesc: true,
    orderBy: ['createdAt'],
    orderByConst: constants.ORDER_BY_TASK_CREATED_AT,
  };

  constructor(
    private createOnlineTaskInspectionService: CreateOnlineTaskInspectionService,
    private taskByTokenIdService: TaskByTokenIdService,
    private taskUpdateService: TaskUpdateService,
    private taskRemoveService: TaskRemoveService,
    private completeTaskService: CompleteTaskService,
    private taskFileUploadService: UploadTaskFileService,
    private removeTaskFileService: RemoveTaskFileService,
    private removeInternalTaskFileService: RemoveInternalTaskFileService,
    private onlineTaskInspectionDoneService: OnlineTaskInspectionDoneService,
    router: Router
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.resetState();
      }
    });
  }

  resetState() {
    this.tasksUnknownError = false;
    this.taskHasBeenAssumed = false;
    this.successfullyAssumed = false;
  }

  createOnlineInspection(
    partnerId: string,
    claimNumber: string,
    carLicensePlate: string,
    mutation?: DocumentNode
  ): Promise<any> {
    return this.createOnlineTaskInspectionService
      .createOnlineInspection(
        partnerId,
        claimNumber,
        carLicensePlate,
        mutation as any
      )
      .pipe(
        map((taskData) => {
          return this._mapCreateOnlineInspection(
            taskData.data?.task.createOnlineInspection.output
          );
        })
      )
      .toPromise();
  }

  getTaskByTokenId(tokenId: string, query?: DocumentNode): Observable<any> {
    return this.taskByTokenIdService
      .getTaskByTokenId(tokenId, query as any)
      .pipe(
        map((taskData) => {
          if (taskData.data.task.byTokenId.output === null) {
            throw new Error('Task token is not valid.');
          }
          return this._mapTask(taskData.data.task.byTokenId.output);
        })
      );
  }

  getTaskByTokenIdAndWatch(
    tokenId: string,
    query?: DocumentNode
  ): Observable<any> {
    return this.taskByTokenIdService
      .getTaskByTokenIdAndWatch(tokenId, query as any)
      .valueChanges.pipe(
        map((taskData) => {
          if (taskData.data.task.byTokenId.output === null) {
            throw new Error('Task token is not valid.');
          }
          return this._mapTask(taskData.data.task.byTokenId.output);
        })
      );
  }

  updateTask(
    taskId: string,
    updatePatch: UpdateTaskInterface,
    mutation: DocumentNode
  ) {
    return this.updateTask$(taskId, updatePatch, mutation).toPromise();
  }

  updateTask$(
    taskId: string,
    updatePatch: UpdateTaskInterface,
    mutation: DocumentNode
  ) {
    return this.taskUpdateService.update(taskId, updatePatch, mutation);
  }

  removeTask(taskId: string, mutation: DocumentNode) {
    return this.taskRemoveService.remove(taskId, mutation).toPromise();
  }

  completeTask(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.completeTaskService.complete(taskId, mutation).toPromise();
  }

  uploadFile(
    taskId: string,
    taskFileGroupId: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Observable<FetchResult<any>> {
    return this.taskFileUploadService.uploadFile(
      taskId,
      taskFileGroupId,
      name,
      content,
      extension,
      mutation,
      customPlaceHolderId
    );
  }

  async uploadFileType(
    taskId: string,
    taskFileGroupId: string,
    file: File,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Promise<any> {
    const re = /(?:\.([^.]+))?$/;
    return new Promise((resolve, reject) => {
      readInputFile(file).then((content: string) => {
        this.uploadFile(
          taskId,
          taskFileGroupId,
          file.name.substring(0, file.name.lastIndexOf('.')),
          content,
          re.exec(file.name)![1],
          mutation,
          customPlaceHolderId
        )
          .toPromise()
          .then((resp) => resolve(resp))
          .catch((error) => reject(error));
      });
    });
  }

  removeTaskFile(taskFileId: string, mutation: DocumentNode): Promise<any> {
    return this.removeTaskFileService
      .removeTaskFile(taskFileId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.taskFile.remove.output) {
            return {
              output: this._mapTaskFile(taskData.data.taskFile.remove.output),
              errors: null,
            };
          } else {
            return taskData.data.taskFile.remove;
          }
        })
      )
      .toPromise();
  }

  removeInternalTaskFile(
    taskFileId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.removeInternalTaskFileService
      .removeInternalTaskFile(taskFileId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.taskInternalFile.remove.output) {
            return {
              output: this._mapTaskFile(
                taskData.data.taskInternalFile.remove.output
              ),
              errors: null,
            };
          } else {
            return taskData.data.taskInternalFile.remove;
          }
        })
      )
      .toPromise();
  }

  onlineTaskInspectionDone(taskId: string, tokenId: string) {
    return this.onlineTaskInspectionDoneService
      .onlineTaskInspectionDone(taskId, tokenId)
      .toPromise();
  }

  private _mapPreview(taskData): any {
    const task = { ...taskData };
    task.createdAt = new Date(task.createdAt);
    if (!task.image) {
      task.image = {
        thumbnails: [
          {
            type: constants.THUMBNAIL_TYPE_SMALL,
            src: './assets/img/task-service-preview-default.svg',
          },
        ],
      };
    } else if (task.image.thumbnails.length === 0) {
      task.image = {
        thumbnails: [
          {
            type: constants.THUMBNAIL_TYPE_SMALL,
            src: './assets/img/task-service-preview-default.svg',
          },
        ],
      };
    }
    return task;
  }

  private _mapCreateOnlineInspection(
    taskData
  ): CreateOnlineInspectionInterface {
    const task = { ...taskData };
    return task;
  }

  private _mapTask(taskData): TaskServiceInterface {
    const task: any = this._mapPreview(taskData);
    task.changedAt = task.changedAt ? new Date(task.changedAt) : null;
    task.createdAt = task.createdAt ? new Date(task.createdAt) : null;
    task.updatedAt = task.updatedAt ? new Date(task.updatedAt) : null;

    if ((task.documentationCompletedDate === undefined || task.documentationCompletedDate === null) === false) {
      task.documentationCompletedDate = new Date(task.documentationCompletedDate);
    }

    task.carTechnicalCertificateValidityDate =
      task.carTechnicalCertificateValidityDate
        ? new Date(task.carTechnicalCertificateValidityDate)
        : null;
    task.carYearOperationFrom = task.carYearOperationFrom
      ? new Date(task.carYearOperationFrom)
      : null;
    task.dateOfLoss = task.dateOfLoss ? new Date(task.dateOfLoss) : null;
    task.executionDate = task.executionDate
      ? new Date(task.executionDate)
      : null;
    if (
      task.taskFileGroups &&
      task.taskFileGroups.length > 0 &&
      task.taskFileGroups[0].messages !== undefined
    ) {
      task.canBeCalculated = task.taskFileGroups.every(
        (fileGroup) =>
          fileGroup.state === constants.TASK_FILE_GROUP_STATE_APPROVED
      );
      task.taskFileGroups = task.taskFileGroups.map((taskFileGroup) =>
        this._mapTaskFileGroup(taskFileGroup)
      );
    }
    if (task.revisionMessages) {
      task.revisionMessages = task.revisionMessages.map((taskRevisionMessage) =>
        this._mapTaskRevisionMessage(taskRevisionMessage)
      );
    }
    return task;
  }

  private _mapTaskFileGroup(fileGroupData): TaskFileGroupInterface {
    const taskFileGroup = { ...fileGroupData };
    taskFileGroup.messages = fileGroupData.messages.map((fileGroupMessage) => {
      const newFileGroupMessage = { ...fileGroupMessage };
      newFileGroupMessage.createdAt = new Date(fileGroupMessage.createdAt);
      return newFileGroupMessage;
    });
    return taskFileGroup;
  }

  private _mapTaskFile(taskFileData): TaskFileInterface {
    const taskFile = { ...taskFileData };
    taskFile.createdAt = new Date(taskFile.createdAt);
    taskFile.updatedAt = new Date(taskFile.updatedAt);
    taskFile.deletedAt = taskFile.deletedAt
      ? new Date(taskFile.deletedAt)
      : null;
    return taskFile;
  }

  private _mapTaskRevisionMessage(
    revisionMessageData
  ): RevisionMessageInterface {
    const revisionMessage = { ...revisionMessageData };
    revisionMessage.createdAt = new Date(revisionMessage.createdAt);
    return revisionMessage;
  }
}
