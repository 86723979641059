import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { OnlineTaskInspectionDetailInspectionInterface } from './definitions/online-task-inspection-detail-interface';

interface TaskByTokenIdResponse {
  task: {
    byTokenId: {
      output: OnlineTaskInspectionDetailInspectionInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TaskByTokenIdService {
  constructor(private apollo: Apollo) {}

  /**
   * Watch task and if token is valid return task, if token is invalid throw exception
   * @param {string} tokenId
   * @param {DocumentNode} query
   * @returns {QueryRef<TaskByTokenIdResponse>}
   */
  getTaskByTokenIdAndWatch(
    tokenId: string,
    query: DocumentNode
  ): QueryRef<TaskByTokenIdResponse> {
    return this.apollo.watchQuery<TaskByTokenIdResponse>({
      query: query,
      variables: {
        tokenId: tokenId,
      },
      pollInterval: 15000,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Return task if token is valid, if token is invalid throw exception
   * @param {string} tokenId
   * @param {DocumentNode} query
   * @returns {Observable<ApolloQueryResult<TaskByTokenIdResponse>>}
   */
  getTaskByTokenId(tokenId: string, query: DocumentNode) {
    return this.apollo.query<TaskByTokenIdResponse>({
      query: query,
      variables: {
        tokenId: tokenId,
      },
    });
  }
}
