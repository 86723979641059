import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { constants } from '../../../shared/utils/constants';
import { LightboxComponent } from '../lightbox/lightbox.component';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @Input() serverFileData: FileInterface;
  @Input() isLoaded = false;
  @Input() uploadedPercent = 100;
  @Input() width = 140;
  @Input() selected: boolean = false;
  @Input() badge: '';
  @Input() isDragDisabled: boolean = false;
  @Output() onDelete: EventEmitter<{}> = new EventEmitter();
  @Output() onDragStart: EventEmitter<{}> = new EventEmitter();
  @Output() onDragEnd: EventEmitter<{}> = new EventEmitter();
  nativeElement: any;
  public loadingFailed = false;

  constructor(private elRef: ElementRef) {
    this.nativeElement = this.elRef.nativeElement;
  }

  get isLightboxSupported(): boolean {
    return LightboxComponent.formats.includes(
      this.serverFileData.extension.toLowerCase()
    );
  }

  get isEmpty(): boolean {
    return this.serverFileData.size === 0;
  }

  get hasPreview(): boolean {
    return !this.isEmpty && !!this.previewPath;
  }

  get _isLoaded(): boolean {
    return this.uploadedPercent >= 100;
  }

  get previewPath(): string {
    let previewFile: FileThumbnailInterface | boolean = false;
    if (!!this.serverFileData.thumbnails) {
      previewFile = this.serverFileData.thumbnails.find((thumbnail) => {
        return thumbnail.type === constants.THUMBNAIL_TYPE_SMALL;
      });
    }
    return previewFile ? previewFile.src : null;
  }

  ngOnInit(): void {
    // console.log(this.serverFileData);
    // if (this.uploadedPercent >= 100) {
    //   this.isLoaded = true;
    // }
  }

  public onLoadingFailed(): void {
    this.loadingFailed = true;
  }
}

interface FileInterface {
  id: string;
  src: string;
  extension: string;
  name: string;
  size?: number | null;
  thumbnails: Array<FileThumbnailInterface>;
}

interface FileThumbnailInterface {
  type: string;
  src: string;
}
