import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { taskDataQuery } from '../../online-task-inspection/online-task-inspection-detail/online-task-inspection-detail.data';
import { TasksService } from '../services/tasks/tasks.service';
import { OnlineTaskInspectionDetail } from '../../online-task-inspection/online-task-inspection-detail/online-task-inspection-detail.interface';
import { authStoreToken } from '../utils';

@Injectable()
export class OnlineTaskInspectionGuard implements CanActivate {
  constructor(private router: Router, private tasksService: TasksService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = route.paramMap.get('token');
    let success = false;
    await this.tasksService
      .getTaskByTokenId(token, taskDataQuery)
      .toPromise()
      .then((task: OnlineTaskInspectionDetail) => {
        authStoreToken(task.auth);
        success = true;
      })
      .catch((x) => {
        this.router.navigate(['/online-task-inspection'], {
          queryParams: { returnUrl: state.url },
        });
        success = false;
      });

    return success;
  }
}
