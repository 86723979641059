import { errorTypeDef } from './error-type-def';

export const errorsTypeDef = `
  errors {
    ${errorTypeDef}
  }
  status
  message
  errorData
`;
