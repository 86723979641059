import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AttachmentFileInterface } from '../attachment-block/attachment-file-interface';
import { IdGeneratorService } from '../../../shared/services/id-generator.service';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { applyUrl } from '../../../shared/utils/url-service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LightboxComponent } from '../lightbox/lightbox.component';

@Component({
  selector: 'app-vertical-files-block',
  templateUrl: './vertical-files-block.component.html',
  styleUrls: ['./vertical-files-block.component.scss'],
})
export class VerticalFilesBlockComponent implements OnDestroy {
  readonly MIN_FILE_WIDTH = 120;
  readonly MAX_FILE_WIDTH = 220;
  @Input() files: Array<AttachmentFileInterface> = [];
  @Input() selectedFileList: Array<string> = [];
  @Input() uploadingFileList: Array<{ id: string; loaded: number }> = [];
  @Input() query = '';
  @Input() isDisabled = false;
  @Input() isAllowedRemoving = true;
  @Input() isAllowedSetDefault = false;
  @Output() file = new EventEmitter<{ file: File }>();
  @Output() onSelectFile = new EventEmitter<{
    fileId: string;
    isMultiSelecting: boolean;
    isDragStarted: boolean;
  }>();
  @Output() onDefaultFile = new EventEmitter<{ fileId: string }>();
  @Output() onDeleteFile = new EventEmitter<{ fileId: string }>();
  @Output() onDeleteAllSelected = new EventEmitter<{}>();
  @Output() onDragStart = new EventEmitter<{}>();
  @Output() onDragEnd = new EventEmitter<{}>();
  fileWidth = 140;
  id: string;
  applyUrl = applyUrl;
  private lbOverlayRef: OverlayRef;

  @ViewChild(ContextMenuComponent)
  public basicMenu: ContextMenuComponent;

  constructor(
    private idGenService: IdGeneratorService,
    private overlay: Overlay
  ) {
    this.id = idGenService.getId('attachment-block');
  }

  public ngOnDestroy(): void {
    if (this.lbOverlayRef) {
      this.lbOverlayRef.detach();
    }
  }

  onContextMenuOpen(fileId: string) {
    if (this.selectedFileList.length === 0) {
      this.selectFile(fileId, null);
    }
  }

  getUploadedForFile(id) {
    const file = this.uploadingFileList.find(
      (fileSource) => fileSource.id === id
    );
    return file ? file.loaded : 100;
  }

  uploadFile(e): void {
    const target: HTMLInputElement = e.target;
    for (let i = 0; i < target.files.length; i++) {
      this.file.emit({ file: target.files.item(i) });
    }
    e.target.value = '';
  }

  public openLightbox(id: string): void {
    let active = 0;
    const files = [];
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (id === file.id) {
        active = files.length;
        // When file is not supported, dont open lightbox.
        if (
          !(file as any).extension ||
          !LightboxComponent.formats.includes(
            (file as any).extension.toLowerCase()
          )
        ) {
          return;
        }
      }
      files.push({
        src: file.src,
        thumb: file.src,
        name: file.name,
        size: file.size,
        extension: (file as any).extension,
      });
    }
    this.lbOverlayRef = this.overlay.create();
    const componentPortal = new ComponentPortal(LightboxComponent);
    const lightboxRef = this.lbOverlayRef.attach(componentPortal);
    lightboxRef.instance.setFiles(files, active);
    lightboxRef.instance.close.subscribe(() => this.lbOverlayRef.detach());
  }

  setDefaultFile(id: string): void {
    this.onDefaultFile.emit({ fileId: id });
  }

  deleteAllSelectedFiles() {
    if (this.isAllowedRemoving) {
      this.onDeleteAllSelected.emit();
    }
  }

  deleteFile(id: string): void {
    if (this.isAllowedRemoving) {
      this.onDeleteFile.emit({ fileId: id });
    }
  }

  selectFile(id: string, event): void {
    const isMultiSelecting =
      event &&
      (event.ctrlKey ||
        event.metaKey ||
        (event.event && (event.event.ctrlKey || event.event.metaKey)));
    if (!isMultiSelecting && event && event.button === 0) {
      this.openLightbox(id);
    }
    this.onSelectFile.emit({
      fileId: id,
      isMultiSelecting: isMultiSelecting,
      isDragStarted: false,
    });
  }

  dragStart(fileId: string, event: any): void {
    const isMultiSelecting =
      event &&
      (event.mouseEvent.ctrlKey ||
        event.mouseEvent.metaKey);
    this.onSelectFile.emit({
      fileId: fileId,
      isMultiSelecting: isMultiSelecting,
      isDragStarted: true,
    });
    this.onDragStart.emit({ event });
  }

  isSelected(fileId: string): boolean {
    return !!this.selectedFileList.find((id) => fileId === id);
  }

  get filesQueried() {
    if (this.query !== '') {
      return this.files.filter((file) => {
        const filename = file.name + '.' + file.suffix;
        return filename.toLowerCase().includes(this.query.toLowerCase());
      });
    }
    return this.files as Array<any>;
  }
}
