import { environment } from '../environments/environment';
import { authRestoreToken } from '../app/shared/utils';
import { WebSocketLink } from '@apollo/client/link/ws';

const wsClient = new WebSocketLink({
  uri: environment.apollo.subscriptionsUrl,
  options: {
    reconnect: true,
    connectionParams: () => {
      const authData = authRestoreToken();
      if (!authData) {
        return {};
      }
      const authToken = authData.token;
      if (authToken) {
        return {
          authToken,
        };
      }
      return {};
    },
  },
});

export default wsClient;
