<div class="row photos">
    <div class="col-12">
        <div class="uploadedFiles">
            <div class="attachmentWrap">
                <app-drop-file-loader (file)="saveNewTaskFile(task.id, $event.file, null)"
                                      [active]="fileDragSourceId === null">
                    <app-vertical-files-block
                            [files]="taskFilesWithoutGroup"
                            [selectedFileList]="selectedFileList"
                            [uploadingFileList]="filesLoadedPercentage"
                            (file)="saveNewTaskFile(task.id, $event.file, null);"
                            (onDeleteFile)="deleteFile($event.fileId)"
                            (onDeleteAllSelected)="deleteAllSelectedFiles()"
                            (onSelectFile)="handleFileSelect($event)"
                    ></app-vertical-files-block>
                </app-drop-file-loader>
            </div>
        </div>
    </div>
</div>
