import { NgModule } from '@angular/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { OnlineTaskInspectionComponent } from './online-task-inspection.component';
import { OnlineTaskInspectionGuard } from '../shared/guard/online-task-inspection.guard';
import { CommonModule } from '@angular/common';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesModule } from '../shared/services/services.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../components-gallery/shared/shared.module';
import { FindTaskComponent } from './find-task/find-task.component';
import { OnlineTaskInspectionDetailComponent } from './online-task-inspection-detail/online-task-inspection-detail.component';
import { FindTaskModule } from './find-task/find-task.module';
import { OnlineTaskInspectionDetailModule } from './online-task-inspection-detail/online-task-inspection-detail.module';
import { OnlineTaskInspectionRoutingModule } from './online-task-inspection-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TasksModule } from '../shared/services/graphql/tasks/tasks.module';
import { TasksFileModule } from '../shared/services/graphql/task-file/tasks-file.module';
import { TasksInternalFileModule } from '../shared/services/graphql/task-internal-file/tasks-internal-file.module';

@NgModule({
  imports: [
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    CommonModule,
    OnlineTaskInspectionRoutingModule,
    ServicesModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgbModule,
    NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatSnackBarModule,
    FindTaskModule,
    OnlineTaskInspectionDetailModule,
    TasksModule,
    TasksFileModule,
    TasksInternalFileModule
  ],
  declarations: [OnlineTaskInspectionComponent],
  providers: [
    OnlineTaskInspectionGuard,
    FindTaskComponent,
    OnlineTaskInspectionDetailComponent,
  ],
})
export class OnlineTaskInspectionModule {}
