import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { QueryRef } from 'apollo-angular';
import { DocumentNode } from 'graphql';

export abstract class ListServiceBase {
  private result: QueryRef<any> | null = null;
  private counter = 0;

  getSubscription(subscriber): Subscription {
    return this.result.valueChanges.subscribe(
      (data) => {
        subscriber.next(data);
      },
      (error) => {
        subscriber.error(error);
      },
      () => {
        subscriber.complete();
      }
    );
  }

  getList(variables = null, querySource?: DocumentNode): Observable<any> {
    const that = this;
    return Observable.create((subscriber) => {
      that.counter++;
      if (that.result === null) {
        that.result = that.getQuery(variables, querySource);
      }
      const subscriptionToResult = that.getSubscription(subscriber);
      return () => {
        that.counter--;
        subscriptionToResult.unsubscribe();
        if (this.counter === 0) {
          that.result = null;
        }
      };
    });
  }

  abstract getQuery(variables, querySource?: DocumentNode): QueryRef<any>;
}
