import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { CreateOnlineInspectionInterface } from './definitions/create-online-inspection-interface';

interface CreateOnlineInspectionResponse {
  task: {
    createOnlineInspection: {
      output: CreateOnlineInspectionInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CreateOnlineTaskInspectionService {
  constructor(private apollo: Apollo) {}

  /**
   * Return task if token is valid, if token is invalid throw exception
   * @param {string} partnerId
   * @param {string} claimNumber
   * @param {string} carLicensePlate
   * @param {DocumentNode} mutation
   * @returns {Observable<ApolloQueryResult<CreateOnlineInspectionResponse>>}
   */
  createOnlineInspection(
    partnerId: string,
    claimNumber: string,
    carLicensePlate: string,
    mutation: DocumentNode
  ) {
    return this.apollo.mutate<CreateOnlineInspectionResponse>({
      mutation: mutation,
      variables: {
        partnerId: partnerId,
        claimNumber: claimNumber,
        carLicensePlate: carLicensePlate,
      },
    });
  }
}
